.avatar{
  border:1px solid $grey-neutral;
  margin: 0 1rem 1rem 0;
  max-width:100px;
  display: inline-block;
  position: relative;

  &.avatar-sm{
    max-width:70px;
    margin: 0 .5rem .5rem 0;
  }

  img.media-object{
    width: 100%;
    height: auto;
  }

  &:not(.avatar-logo){
    border-radius: 100%;

    img.media-object{
      border-radius: 100%;
    }
  }
}

.project-header .avatar,
.profile-header .avatar{
  margin: 0 0 .5rem;
  width: 100%;

  @include respond-to(small){
    max-width:100%;
  }
}

.img-bw {
  filter: grayscale(100);
}

.document-image{
  max-width: 70px;
  width: 100%;
  height: auto;
}

.document-label{
  background: $fl_accent_base;
  color: $white;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  top: 4px;
  left: 6px;
  padding: 2px 8px;
  box-shadow: $shadow-primary;
}

.ul-blog img{
  width: 120px;
  height: auto;
}