.btn {
  border-radius:6px;
  background: $black;
  color: $white;
  border: 2px solid transparent;
  transition: all $transition-normal;
  padding: .5em 1.5em;
  font-size: .875rem;
  font-weight: 700!important;

  &:hover,
  &:focus,
  &:active,
  &:active:hover,
  &:focus:active{
    background: $fl_secondary_light-50;
    border-color: $black;
    color: $black;
  }

  &.dropdown-toggle span{
    font-weight: 400;
  }

  &.btn-search{
    text-transform: uppercase;
  }
}

.btn-cta,
.btn-primary {
  background: $fl_secondary_base;
  color: $black;
}

.btn-secondary {
  background: $fl_gray_light-10;
  color: $fl_gray_base;
}

.btn.disabled,
.btn:disabled,
.btn[disabled],
fieldset[disabled] .btn{
  background-color: $fl_gray_light-10!important;
  border:2px solid $fl_gray_light-10!important;
  color: $fl_gray_light-30!important;
  opacity: 1;
}

.btn-xs,
.btn-group-xs > .btn{
  font-size: .75rem;
  padding: .25em 1em;
}

.btn-sm,
.btn-group-sm > .btn{
  font-size: .75rem;
}

.btn-lg,
.btn-group-lg > .btn{
  font-size: 1.125rem;
}

.btn-group-xs > .btn {
  border-radius: 0;
  padding: .25em 1em;
}

.btn-group-sm > .btn,
.btn-group-lg > .btn {
  border-radius: 0;
  padding: .5em 1.5em;
}


@mixin button-variant($color, $bg, $border, $hover-color, $hover-bg) {
  color: $color;
  background-color: $bg;
  border: 2px solid $border;

  &:hover,
  &:focus {
    color: $hover-color;
    background-color: $hover-bg;
    border-color:$black;
  }

  .badge {
    color: $bg;
    background-color: $color;
  }
}

.btn-success{
  @include button-variant($white, $fl_success_base, transparent, $black, $fl_success_light-30);
}

.btn-info {
  @include button-variant($black, $fl_secondary_base, transparent, $black, $fl_secondary_light-50);
}

.btn-warning{
  @include button-variant($white, $fl_primary_base, transparent, $black, $fl_primary_light-30);
}

.btn-danger{
  @include button-variant($white, $fl_danger_base, transparent, $black, $fl_danger_light-30);
}

/* btn-group focus override */
.btn-group .btn-warning:focus {
    background: $fl_primary_base;
    color: $white;
    border-color: transparent;
}