// Scalable badge
.badge,
.panel-default > .panel-heading .badge{
  font-size: .75em;
  padding: .25em .5em;
  border-radius: 999px;
  position: relative;
  top: -.2em;
  background-color: $brand-primary;
  min-width: 2em;
  color: $white;
}

.badge-success,
.panel-default > .panel-heading .badge-success {
  background-color: $brand-success;
}

.badge-info,
.panel-default > .panel-heading .badge-info {
  background-color: $brand-info;
}

.badge-warning,
.panel-default > .panel-heading .badge-warning {
  background-color: $brand-warning;
}

.badge-danger,
.panel-default > .panel-heading .badge-danger{
  background-color: $brand-danger;
}

a.badge:hover,
a.badge:focus{
  box-shadow: $shadow-inset;
}

.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: $white;
  background-color: $brand-primary;
}